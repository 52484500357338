import CryptoJS from 'crypto-js';
import { JwtPayload, jwtDecode } from 'jwt-decode';
import * as buffer from 'buffer';
import { useNavigate } from "react-router-dom";

function refreshPage (params:string) {
    
}

function moveToPageWithBack (params:string) {
    
}

function moveToPageAndClearHistory(page:string) {
  // 새로운 페이지 URL을 히스토리 스택에 추가하고 이전 스택을 모두 제거합니다.
  window.history.pushState(null, '', page);

  // 새로운 페이지로 이동합니다.
 return window.location.href = page;
}




function redirectToPageAndReset (params:string) {
   return window.location.href = "/";
}

function aesEncodeLogin (key_vet_aes:string,params:string) {
  const key = CryptoJS.enc.Utf8.parse(key_vet_aes);
  const iv = CryptoJS.enc.Utf8.parse(key_vet_aes.substring(0, 16));
  const encrypted = CryptoJS.AES.encrypt(params, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC
  }); 
   return encrypted.toString();
 
}


function getEncMD5Login(input: string): string {
  const md5Hash = CryptoJS.MD5(input).toString(CryptoJS.enc.Hex);
  return md5Hash.padStart(32, '0');
}

function getUserIdJwt(token: string) {
  const payload = jwtDecode(token) as JwtPayload;
  const nUserId = JSON.parse(JSON.stringify(payload));
  return nUserId['id']??"";
}

function setWithdrawalReason(type: string){
  let reason = "";
   switch (type) {
    case '1':
      reason = '자주 사용 안 함'
      break;
    case '2':
      reason = 'UX/UI 불편'
      break;  
    case '3':
      reason = '원하는 기능 없음'
      break;    
    default:
      reason = '자주 사용 안 함'
      break;
   }
   return reason;
}


const commonFunctions ={
  refreshPage,
  moveToPageWithBack,
  redirectToPageAndReset,
  moveToPageAndClearHistory,
  aesEncodeLogin,
  getEncMD5Login,
  getUserIdJwt,
  setWithdrawalReason
}
export{commonFunctions}