import React ,{useState, useRef} from 'react'
import {FormControlLabel ,FormControl,FormLabel,RadioGroup,Radio } from '@mui/material';
import { apiManager } from 'Services/apiManager';
import { urlManager } from 'Services/urlManager';
import { commonFunctions } from 'Services/commonFunctions';

export default function ContentConfirmation(props:any) {
  const [withdrawalTypeReason, setwithdrawalTypeReason] = useState('1');
  const inputRef = useRef<HTMLInputElement>(null);

  const handleWithdrawalBtn = () => {
    if(withdrawalTypeReason==='0'){
      const input = inputRef.current?.value.trim();
      if(input!=""){
        console.log(input);
        console.log(props.user_info);
        console.log(props.user_info.withdrawalType);
        console.log('탈퇴전송');
        props.user_info.withdrawalType==1?homeWithdrawaApi():vetWithdrawaApi();
      }else{
        alert('탈퇴사유를 입력하여 주세요');
      }
    }else{
      //전송
      console.log(props.user_info);
      console.log(props.user_info.withdrawalType);
      console.log('탈퇴전송');
      props.user_info.withdrawalType==1?homeWithdrawaApi():vetWithdrawaApi();
    }
  };




  function vetWithdrawaApi(){
    let _userInfo = {
      withdrawalType:2,
      withdrawalStep:3,
      userId:`${props.user_info.userId}`,
      nickname:`${props.user_info.nickname}`,
      accessToken : `${props.user_info.accessToken}`,
      refreshToken : `${props.user_info.refreshToken}`,
      reasonType : `${withdrawalTypeReason}`,
      reason : `${withdrawalTypeReason=='0'?inputRef.current?.value.trim().toString():commonFunctions.setWithdrawalReason(withdrawalTypeReason)}`,}

      let headers = {
        "authorization":`${props.user_info.accessToken}`,
        "x-refresh":`${props.user_info.refreshToken}`,
      }
      let data = {
        "reasonCode":`${withdrawalTypeReason}`,
        "reason":`${withdrawalTypeReason=='0'?inputRef.current?.value.trim().toString():commonFunctions.setWithdrawalReason(withdrawalTypeReason)}`,
        "appCode":2001,
      }
        apiManager.deleteAxios(
          `${urlManager.hospitalHost}/api/v3/hospital/users/${props.user_info.userId}`,
        {headers:headers,data:data})
        .then((re)=>{
          console.log(re);
          console.log('성공');
          console.log(re);
          return props.updateUserInfo(_userInfo);
        }).catch((err)=>{
          console.log(err);
          console.clear();
          alert('탈퇴신청 실패 관리자에게 문의해주세요');
        });

  }

  function homeWithdrawaApi(){
    console.log('homeWithdrawaApi');
    let _userInfo = {
      withdrawalType:1,
      withdrawalStep:3,
      userId:`${props.user_info.userId}`,
      nickname:`${props.user_info.nickname}`,
      accessToken : `${props.user_info.accessToken}`,
      refreshToken : `${props.user_info.refreshToken}`,
      reasonType : `${withdrawalTypeReason}`,
      reason : `${withdrawalTypeReason=='0'?inputRef.current?.value.trim().toString():commonFunctions.setWithdrawalReason(withdrawalTypeReason)}`,}

   let headers = {
      "authorization":`${props.user_info.accessToken}`,
      "x-refresh":`${props.user_info.refreshToken}`,
    }
    let data = {
      "reasonCode":`${withdrawalTypeReason}`,
      "reason":`${withdrawalTypeReason=='0'?inputRef.current?.value.trim().toString():commonFunctions.setWithdrawalReason(withdrawalTypeReason)}`,
      "appCode":1001,
    }
    apiManager.deleteAxios(
      `${urlManager.personalHost}/api/v1/user/reason`,{headers:headers,data:data}).then((re)=>{
      console.log('성공');
      console.log(re);
     return props.updateUserInfo(_userInfo);
    }).catch((err)=>{
      console.log(err);
      console.clear();
      alert('탈퇴신청 실패 관리자에게 문의해주세요');
    });
  }


  return (
    <div>
      <div className='flex-container-start'>
       <h1 className='full-width-container'>
        <span className='service-name' style={{'backgroundColor':`${props.user_info.withdrawalType==1?'#bdd4e7':'#E7BDBD'}`}}>
          {props.user_info.withdrawalType==1?'Dolittle Home':'Dolittle Vet'}</span>을 탈퇴하시나요?
          </h1> 
        <h2>{props.user_info.nickname} 회원님 탈퇴하시는 이유를 알려주세요.</h2> 
           <FormControl className='full-width-container'>
            <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="1"
                  name="radio-buttons-group"
                  className='full-width-container' >
            <FormControlLabel
                value="1"
                control={<Radio checked={withdrawalTypeReason === '1'} />}
                label="자주 사용하지 않아요."
                onChange={() => setwithdrawalTypeReason('1')}
            />
            <FormControlLabel
                value="2"
                control={<Radio checked={withdrawalTypeReason === '2'} />}
                label="UX/UI가 불편해요."
                onChange={() => setwithdrawalTypeReason('2')}
            />
            <FormControlLabel
                value="3"
                control={<Radio checked={withdrawalTypeReason === '3'} />}
                label="원하는 기능이 없어요."
                onChange={() => setwithdrawalTypeReason('3')}
            />

         <div style={{display:'flex'}}>
         <FormControlLabel
                value="0"
                control={<Radio checked={withdrawalTypeReason === '0'} />}
                label="직접 입력"
                onChange={() => setwithdrawalTypeReason('0')}
            />
          <input type="text" ref={inputRef} />
          </div>
        </RadioGroup>
      </FormControl>
      <div className='flex-container-start'>
      <h2 style={{ textAlign:'start', lineHeight:'2em' }}>
        탈퇴 전 , 꼭 확인을 해주세요 .<br/>탈퇴 후 회원님의 모든 정보가 삭제되며 복구는 불가능합니다.</h2>
      </div>
     </div>
      <button
      className='withdrawal-bt'
      style={{'backgroundColor':`${props.user_info.withdrawalType==1?'#bdd4e7':'#E7BDBD'}` }}
      onClick={handleWithdrawalBtn}>탈퇴하기</button>
    </div>
  )
}
