import React , { useEffect, useState }from 'react'
import { useParams, useNavigate, Link } from "react-router-dom";
import {FormGroup,FormControlLabel,Switch,Stack,Typography} from '@mui/material';
import { Footer, Header } from 'Components';
import "./withdrawal.css"
import IdentityVerification from './IdentityVerification';
import ContentConfirmation from './ContentConfirmation';
import ServiceApplicationCompleted from './ServiceApplicationCompleted';
import { urlManager } from 'Services/urlManager';
import { commonFunctions } from 'Services/commonFunctions';

export default function Withdrawal() {
    const [serverType, setServerType] = useState(false);
    let { service } = useParams();  
    const [Info, setInfo] = useState({ withdrawalType:0, withdrawalStep:0,}); 
    
    useEffect(() => {
      console.log(`${localStorage.getItem('isServer')}`);
      if (service?.includes('home')) {
        let info ={
          withdrawalType:1,
          withdrawalStep:1
        }
        updateInfo(info);
      } else if (service?.includes('vet')) {    
        let info ={
          withdrawalType:2,
          withdrawalStep:1
        }
        updateInfo(info);
      } else {
       commonFunctions.moveToPageAndClearHistory('/');
      }
      setServerType(localStorage.getItem('isServer')=='true'?true:false);
    },[]);

    
  const updateInfo = (newInfo:any) => {

    setInfo(prevInfo => ({
        ...prevInfo,
        ...newInfo
    }));
  };

  function changeServer(serverType:boolean){
    localStorage.setItem('isServer', String(!serverType));
    window.location.reload();
}


 return (     
    <>
     {Info.withdrawalStep==0?<>• ◦ • ◦ • ◦ •</>
     :<><Header name={'회원탈퇴'} />
     {urlManager.build?<></>
     :<>
      <FormGroup className='flex-container-center'>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography>테스트 서버</Typography>
              <Switch checked={serverType} onChange={()=>{changeServer(serverType)}}inputProps={{'aria-label': 'ant design' }} />
              <Typography>실제 서버</Typography>
            </Stack>
        </FormGroup>
      </>}
       <div className='container'>
          <ul className="step-container"
          style={{backgroundColor:`${Info.withdrawalType==1?'#bdd4e7':'#E7BDBD'}`}}>
          <li style={{backgroundColor:`${Info.withdrawalStep==1?'white':''}`}} >본인확인</li>
          <li style={{backgroundColor:`${Info.withdrawalStep==2?'white':''}`}} >내용확인</li>
          <li style={{backgroundColor:`${Info.withdrawalStep==3?'white':''}`}}>서비스 신청완료</li>
          </ul>
          <div className='main-container'>
          {Info.withdrawalStep==1?<IdentityVerification type={Info.withdrawalType} updateUserInfo={updateInfo}/>
          :Info.withdrawalStep==2?<ContentConfirmation user_info ={Info} updateUserInfo={updateInfo}/>
          :<ServiceApplicationCompleted user_info ={Info}/>}
          </div>
        </div>
      </>
      }
    </>
  )
}
