import React from 'react'

export default function ServiceApplicationCompleted(props:any) {
  return (
    <div>
      <h2>탈퇴완료</h2>
      <ul>
        <li style={{margin:'2%'}}>
        <span>{props.user_info.nickname}</span>님 탈퇴 처리가 완료되었습니다.
        </li>
        <li> 그동안 
          <span className='service-name'style={{'backgroundColor':`${props.user_info.withdrawalType==1?'#bdd4e7':'#E7BDBD'}`}}>
          {props.user_info.withdrawalType==1?'Dolittle Home':'Dolittle Vet'}</span>
           서비스를 이용해 주셔서 감사합니다.
        </li>
      </ul>
    </div>
  )
}
