import React from 'react'

export default function Main() {
  return (
    <div className='full-height-container full-width-container flex-container-center'>
    <div style={{margin:'10%'}}>
    <a href="https://zentry.kr/">
    <img className='full-width-container'src="/assets/zentry-logo.png" alt="04081" />  
    </a> 
    </div>
    </div>
  )
}
