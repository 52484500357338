import React from 'react';
import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Router from './Router';

function App() {
  if (process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'test') {
    console.log = () => {}
    console.error = () => {}
    console.debug = () => {}
  }
  
  return (
    <div className="App">
      <BrowserRouter>
       <Router></Router>
      </BrowserRouter>
    </div>
  );
}

export default App;
