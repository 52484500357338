const KAKAO_VET_REST_API ="8f15625527d13e55258e814dc072553a";
const KAKAO_VET_JAVASCRIPT_API ="393abf21dd3284c25c487d1ba450d54f";
//const KAKAO_VET_ADMIN_API ="9fb37c4c6c1a3e4272d47b2fbc72a7b3";
const KEY_VET_LOGIN_AES ="vdmflskvmwekwlefkvmwlekvm!abdejk";


const KAKAO_HOME_REST_API ="fd0fac75c204418419bcb62dab5333f0";
const KAKAO_HOME_JAVASCRIPT_API ="cf07b3f666831c067ed587f934aa75be";
//const KAKAO_HOME_ADMIN_API ="39f6c8a5ce4009e50620686192aaf0ac";



const KEY_GOOGLE_CLIENT_ID="398129152523-nurt4bl7thvthlq0qll93lboug3lc89n.apps.googleusercontent.com";


const keyManager ={
    KAKAO_VET_REST_API,
    KAKAO_VET_JAVASCRIPT_API,
    KAKAO_HOME_REST_API,
    KAKAO_HOME_JAVASCRIPT_API,
    KEY_VET_LOGIN_AES,
    KEY_GOOGLE_CLIENT_ID
}

export{keyManager}