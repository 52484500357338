import axios, { Axios } from "axios";



function getAxios(url:string,config?: any){
    return axios.get(url,config);
}

function postAxios(url:string,data?:any,config?: any){
    return axios.post(url,data,config);
}

function patchAxios(url:string,data:any,headers:any,params:any){
    return axios.patch(url,data,{headers,params});
}


function deleteAxios(url:string,config?: any){
    return axios.delete(url,config);
}



const apiManager ={
 getAxios,
 postAxios,
 patchAxios,
 deleteAxios
}

export{apiManager}