import React, { useEffect,useState } from 'react'
import { useParams, useNavigate, Link } from "react-router-dom";
import { GoogleLogin, GoogleOAuthProvider,useGoogleLogin } from '@react-oauth/google';
import { Footer, Header } from 'Components';
import { RiKakaoTalkFill } from "react-icons/ri";
import "Styles/base.css"
import "./withdrawal.css"
import { apiManager } from 'Services/apiManager';
import { urlManager } from 'Services/urlManager';
import { keyManager } from 'Services/keyManager';
import { commonFunctions } from 'Services/commonFunctions';

export default function IdentityVerification(props:any) {
 
 useEffect(() => {
  const urlParams = new URLSearchParams(window.location.search);
  const codeValue = urlParams.get('code'); 
  if(codeValue==null){
  }else{
    kakaoLoginApi(codeValue);
  }

 },[]);
  
 function logout(kakaoAcToken:string){
     let headers ={
    "Content-Type":"application/x-www-form-urlencoded",
    "Authorization":`Bearer ${kakaoAcToken}`
  }
  apiManager.postAxios(`${urlManager.kakaoApiHost}/v1/user/unlink`,{},{headers:headers
  }).then((r)=>{
    //commonFunctions.moveToPageAndClearHistory('/');
  });
 }


 function onKakaoLoginPage(){
  const kakaoAuthUrl = `${urlManager.kakaoLoginHost}/oauth/authorize?response_type=code&client_id=${props.type==1?keyManager.KAKAO_HOME_REST_API:keyManager.KAKAO_VET_REST_API}&redirect_uri=${urlManager.kakaoRedirect}/${props.type==1?"home":"vet"}`;
  return window.location.href = kakaoAuthUrl;
 }

 function kakaoLoginApi(code:string){
  let headers ={
    "Content-type":"application/x-www-form-urlencoded"
  }
  let data = {
    "grant_type":"authorization_code",
    "client_id":`${props.type==1?keyManager.KAKAO_HOME_REST_API:keyManager.KAKAO_VET_REST_API}`,
    "redirect_uri":`${urlManager.kakaoRedirect}/${props.type==1?"home":"vet"}`,
    "code":`${code}`}
  apiManager.postAxios(`${urlManager.kakaoLoginHost}/oauth/token`, data, {
    headers:headers,
    paramsSerializer: function (params:any) {
      return Object.entries<any>(params)
        .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
        .join('&');
    }
  }).then((result) => {
    const accessToken = result.data.access_token;
    kakaoUserInfoApi(accessToken);
  }).catch((err)=>{
    console.log(err);
    console.log(props);
    console.clear();
    alert(err);
    props.type==1?commonFunctions.moveToPageAndClearHistory('/withdrawal/home'):commonFunctions.moveToPageAndClearHistory('/withdrawal/vet');
  });
 }

 function kakaoUserInfoApi(acToken:string){
  let headers ={
    'Authorization':`Bearer ${acToken}`,
    'Content-Type':'application/x-www-form-urlencoded;charset=utf-8',
  }
  apiManager.getAxios(`${urlManager.kakaoApiHost}/v2/user/me`,{headers:headers
  }).then((re)=>{
    
    const user_id = re.data.id
    const user_email = re.data.kakao_account.email;
    const ps = `${user_email};kakao`; 
    const pw = commonFunctions.aesEncodeLogin(keyManager.KEY_VET_LOGIN_AES,ps);
    const pushMessageToken = "";
    logout(acToken);
    return props.type==1?homeDolittleLogin('kakao',user_id,user_email):vetDolittleLogin(pw,pushMessageToken);
  }).catch((err)=>{
    console.log(err);
    console.clear();
    alert(err);
    props.type==1?commonFunctions.moveToPageAndClearHistory('/withdrawal/home'):commonFunctions.moveToPageAndClearHistory('/withdrawal/vet');
  });
 }
 
 const onGoogleLoginPage = useGoogleLogin({
  onSuccess:googleUserInfoApi,
  onError:()=>{alert('error');}
});


 function googleUserInfoApi(params:any){
    const access_token = params.access_token;
    let param ={
      'access_token':`${access_token}`,
    }
      apiManager.getAxios(`${urlManager.googleApiHost}/oauth2/v1/userinfo`,{params:param
    }).then((re)=>{
       const user_id = re.data.id
       const user_email = re.data.email;
       const ps = `${user_email};google`; 
       const pw = commonFunctions.aesEncodeLogin(keyManager.KEY_VET_LOGIN_AES,ps);
       const pushMessageToken = "";
       return props.type==1?homeDolittleLogin('google',user_id,user_email):vetDolittleLogin(pw,pushMessageToken);
    }).catch((err)=>{
      console.log(err);
      console.clear();
      alert(err);
      props.type==1?commonFunctions.moveToPageAndClearHistory('/withdrawal/home'):commonFunctions.moveToPageAndClearHistory('/withdrawal/vet');
    });
 }



 function homeDolittleLogin(authType:any,user_id:any,user_email:any){
  const pw = commonFunctions.getEncMD5Login(`${user_email}${user_id}`);

  let data = {
    "authType":`${authType}`,
    "authId":`${user_id}`,
    "email":`${user_email}`,
    "password":`${pw}`
  }
  apiManager.postAxios(`${urlManager.authHost}/api/v3/pet/auth`,data
  ).then((re)=>{  
    let _userInfo = {
      withdrawalType:1,
      withdrawalStep:2,
      userId:`${re.data.userId}`,
      nickname:`${re.data.nickname}`,
      accessToken : `${re.data.accessToken}`,
      refreshToken : `${re.data.refreshToken}`
    
    }
  return props.updateUserInfo(_userInfo);
  }).catch((error)=>{
    console.clear();
     let _userInfo = {
      withdrawalType:1,
      withdrawalStep:1 }
    props.updateUserInfo(_userInfo);
    const errMsg = error.response.data.error['message'];
    if(errMsg.includes("Can't find a user")){
      alert('해당 정보로 가입된 내역이 존재하지 않습니다.');
    }else{
      alert(errMsg);
    }
  });
 }


 function vetDolittleLogin(pw:any,pushMessageToken:any){
 let data = {
    "password":`${pw}`,
    "pushMessageToken":`${pushMessageToken}`
  }
  apiManager.postAxios(`${urlManager.authHost}/api/v3/vet/auth`,data
  ).then((re)=>{
    const acToken = re.data.accessToken;
    const reToken = re.data.refreshToken;
    const user_id = commonFunctions.getUserIdJwt(reToken); 
    getVetUserInfo(acToken,reToken,user_id);
  
  }).catch((error)=>{
    console.clear();
    let _userInfo = {
      withdrawalType:2,
      withdrawalStep:1 }
    props.updateUserInfo(_userInfo);
    const errMsg = error.response.data.error['message'];
    if(errMsg.includes("Can't find a user")){
      alert('해당 정보로 가입된 내역이 존재하지 않습니다.');
    }else{
      alert(errMsg);
    }
  });
 }

 function getVetUserInfo(ac_token:any,rc_token:any,user_id:any){
 let headers ={
   "authorization":`${ac_token}`
 }
 apiManager.getAxios(`${urlManager.hospitalHost}/api/v1/hospital/users/${user_id}`,{headers:headers
 }).then((re)=>{
  let _userInfo = {
    withdrawalType:2,
    withdrawalStep:2,
    userId:`${re.data.id}`,
    nickname:`${re.data.name}`,
    accessToken :`${ac_token}`,
    refreshToken :`${rc_token}`,
  
  }
 
return props.updateUserInfo(_userInfo);

 }).catch((err)=>{
  let _userInfo = {
    withdrawalType:2,
    withdrawalStep:1 }
   props.updateUserInfo(_userInfo);
   console.clear();
   alert(err);
   props.type==1?commonFunctions.moveToPageAndClearHistory('/withdrawal/home'):commonFunctions.moveToPageAndClearHistory('/withdrawal/vet');
   
 });
 
}

return (
   <>
   <div className='flex-container-center'>
     <span 
     onClick={onKakaoLoginPage}
     className='loginImage-container' 
     style={{backgroundColor:'#FEE500'}
     }>
     <RiKakaoTalkFill/>
        <span className='full-width-container' >
           Login with Kakao
        </span>
      </span>
      <span 
         className='loginImage-container'
         style={{backgroundColor:'#F2F2F2',paddingBottom:'0.2%',paddingTop:'0.2%'}} >
        <img src="/assets/google_ico.png" alt="" />
        <span className='full-width-container'onClick={()=>{onGoogleLoginPage();}}>
           Sign in with Google
        </span>
      </span>  
    </div>
   </>
  )
}
