

const build = false;
let isbool = build?true:localStorage.getItem('isServer')=='true'?true:false;
const local ="http://localhost:3000";
const devSupportHost= "https://support-dev.zentry.kr"
const supportHost= "https://support.zentry.kr"
//const kakaoRedirect=`${local}/withdrawal`;

const kakaoRedirect=`${isbool?supportHost:devSupportHost}/withdrawal`;
const kakaoLoginHost ="https://kauth.kakao.com";
const kakaoApiHost ="https://kapi.kakao.com";
const googleApiHost ="https://www.googleapis.com";

const authServer = "https://api.moon-cluster.zentry.kr/auth";
const testAuthServer = "https://api.dolittle-dev-cluster.zentry.kr/auth";

const personalServer = "https://api.moon-cluster.zentry.kr";
const testPersonalServer = "http://api.personal-dev.zentry.kr";

const hospitalServer = "https://api.moon-cluster.zentry.kr/hospital";
const testHospitalServer = "https://api.dolittle-dev-cluster.zentry.kr/hospital";



const authHost = isbool?authServer:testAuthServer;
const hospitalHost = isbool?hospitalServer:testHospitalServer;
const personalHost = isbool?personalServer:testPersonalServer;

const urlManager ={
    build,
    isbool,
    kakaoRedirect,
    kakaoLoginHost,
    kakaoApiHost,
    authHost,
    hospitalHost,
    personalHost,
    googleApiHost
 
}

export{urlManager}