import React from 'react'
import { Route, Routes } from 'react-router-dom'
import {
  Main,
  Withdrawal,
  IdentityVerification,ContentConfirmation,ServiceApplicationCompleted} from "Pages";


export default function Router() {
  return (
    <Routes>
    <Route path="/" element={<Main />} />
    <Route path="*" element= {<Main /> } />
    <Route path="/withdrawal" element={<Withdrawal />} />
    <Route path="/withdrawal/:service" element={<Withdrawal />} />
    </Routes>
  )
}

